.tags-container-main {
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 25px 0px;
}

.tags-container {
  display: flex;
  gap: 15px;
}
.tags-container-level {
  display: flex;
  /* flex-direction: column; */
  width:110px;
  max-width: 150px;
  gap: 15px;
}

.tag-wrapper {
  margin-bottom: 10px;
}

.single-tag {
  border-radius: 10px;
  width: auto;
  padding: 10px;
  height: 30px;
  font-size: 14px;
  width:max-content;
  color: var(--black-clr);
  cursor: pointer;
  background: var(--lightest-grey);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .single-tag.selected {
  background: rgb(240, 98, 147);
} */
