body {
  margin: 0;
  *,
  *:after,
  *:before {
    margin: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

button {
  cursor: pointer;
}
body {
  background-color: var(--white-clr);
  overflow-x: hidden;
}
.bold-link {
  font-weight: bold;
  text-decoration: underline;
  color: blue;
}
