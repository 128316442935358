.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .popup {
    background-color: white;
    padding: 40px 30px;
    width: 90%;
    height: 70%;
    overflow: auto;
    max-width: 70%;
    border-radius: 15px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(12px);
  }

  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .title-container {
      display: flex;
      gap: 5px;
      svg {
        position: relative;
        top: 2px;
      }
    }

    h2 {
      margin: 0;
      color: var(--text-clr);
      font-size: 16px;
      text-decoration-line: underline;
    }

    .close-btn {
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }

  .popup-content {
    line-height: 1.6;
    color: var(--text-clr);
  }
}
