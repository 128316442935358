.images-container {
    display: flex;
    /* align-items: center; */
    gap: 20px;
    max-width: 100%;
    flex-wrap: wrap;
    // padding: 0px 25px 0px 0px;

    .image-container {
      width: fit-content;
      height: fit-content;
      padding: 14px 47px 10px 47px;
      background: #e3e3e3;
      position: relative;
      border-radius: 10px;
      .delete-btn {
        position: absolute;
        right: 7px;
        cursor: pointer;
      }

      img{
        border-radius: 4px;
      }
    }
  }
  