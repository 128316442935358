:root{
  --main-clr:rgba(0, 0, 0, 1);
  --main-undertone-clr:rgba(0, 0, 0, 1);
  --secondary-clr:rgba(0, 0, 0, 1);

  --text-clr:rgba(19, 19, 19, 1);
  --disabled-text-clr:rgba(190, 187, 187, 0.8);
  --inputs-bg-color: rgba(227, 227, 227, 0.8);
  --background-second-clr-lm: rgba(227, 227, 227, 1);
  --background-clr: rgb(30, 40, 90);
  
  --dark-blue-clr: rgba(30, 40, 90, 1);
  --white-clr: rgba(255, 255, 255, 1);
  --lightest-grey: rgba(227, 227, 227, 1);
  --light-green-clr: rgba(44, 255, 144, 1);
  --purple-clr: rgba(93, 59, 255, 1);
  --light-purple-clr: rgba(134, 57, 214, 1);
  --pink-clr: rgba(255, 50, 92, 1);
  --darker-grey: rgba(32, 32, 32, 1);
  --darkest-grey: rgba(19, 19, 19, 1);
  --cyan-clr: rgba(107, 215, 253, 1);
  --lighter-grey-clr: rgba(227, 227, 227, 1);
  --green-clr: rgba(213, 252, 240, 1);
  --dark-green-clr: rgba(31, 105, 98, 1);
  --darker-faded-cyan: rgb1(46, 59, 63);
  --dark-faded-cyan-clr: hsl(197, 10%, 42%);
  --light-green-clr: rgba(44, 253, 143, 1);
  --lighest-grey-clr:rgba(243, 243, 243, 1);
  
  --tags-level-one-clr: rgba(249, 109, 223, 1);
  --tags-level-two-clr: rgba(250, 180, 237, 1);
  --tags-level-three-clr: rgba(250, 222, 245, 1);
}
@font-face {
  font-family: 'Assistant'; /*Can be any text*/
  src: url('../src/lib/fonts/Assistant-VariableFont_wght.ttf') format('woff');
}
body {
  margin: 0;
  font-family: Assistant !important;
}
body *,
body *:after,
body *:before {
  margin: 0;
  box-sizing: border-box;
}

button {
  cursor: pointer;
}
