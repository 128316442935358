.toggle-env{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  gap: 10px;
  justify-items: center;
  .option{
    
  }
  .toggle-btn {
    cursor: pointer;
    width: 65px;
    height: 14px;
    border-radius: 21px;
    background-color: rgba(255, 50, 92, 0.23);
    position: relative;
    display: flex;
    align-items: center;
    .toggle-circle{
      width: 28px;
      height: 28px;
      background-color: var(--pink-clr); 
      border-radius: 40px;
      position: absolute;
      left: 0;
      transition: left 0.3s;
      &.right{
        left: 39px;
      }
    }
  
  }
  
}
@media only screen and (max-width: 1000px) {
  .toggle-env{
    grid-template-columns: 40px 30px 40px;
    gap: 5px;
    
    .option{
      
    }
    .toggle-btn {
      width: 30px;
      height: 7px;
      .toggle-circle{
        width: 11px;
        height: 11px;
        &.right{
          left: 20px;
        }
      }
    
    }
    
  }
}
