.main-page {
  direction: rtl;
  height: 100%;
  min-height: 100vh;
  .migdal-img-main {
    position: absolute;
    height: 75%;
    left: 0;
    bottom: 0;
  }
  .back-circle {
    position: fixed;
    border-radius: 50%;
    &.purple {
      background-color: var(--purple-clr);
    }
    &.light-purple {
      background-color: var(--light-purple-clr);
    }
    &.pink {
      background-color: var(--pink-clr);
    }
    &.med {
      width: 190px;
      height: 190px;
    }
    &.lg {
      width: 336px;
      height: 336px;
    }
    &.right {
      right: -70px;
      top: 35px;
    }
    &.left {
      left: 174px;
      bottom: 163px;
      &.bottom {
        left: -95px;
        bottom: -120px;
      }
    }
  }
  .welcome-page {
    // padding-right: 83px;
    padding: 100px 40px;
    color: var(--darkest-grey);
    min-height: 100vh;
    position: relative;
    .yes-ai-img {
      margin: 95px 0 135px;
      width: 220px;
      height: 66px;
    }
    .main-title {
      color: var(--darkest-grey);
      font-size: 70px;
      margin-bottom: 45px;
    }

    p {
      font-size: 40px;
      margin-bottom: 25px;
      max-width: 940px;
      margin-top:10px;
      font-weight: 600;
    }

    .input-field-container {
      width: 800px;
      margin-right: 0;
      display: flex;
      align-items: center;
      .input-field {
        // font-size: 16px;
      }
    }

    .footer {
      font-size: 14px;
      opacity: 0.38;
      position: fixed;
      bottom: 10px;
      color: white;
      left: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      a {
        opacity: 0.75;
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .main-page {
    .welcome-page {
      .migdal-img-main {
        display: none;
      }
      .yes-ai-img {
        margin: 60px 0 100px 0;
      }

      .main-title {
        font-size: 48px;
        margin-bottom: 30px;
      }

      p {
        font-size: 28px;
        margin-bottom: 33px;
        max-width: 700px;
      }

      .input-field-container {
        width: 700px;
      }

      .input-field {
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .main-page {
    .welcome-page {
      padding-right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: calc(100vh);

      .yes-ai-img {
        margin: 135px 0 95px 0;
        width: 200px;
        height: 50px;
      }

      .main-title {
        font-size: 36px;
        margin-bottom: 10px;
      }

      p {
        text-align: center;
        max-width: 90%;
        font-size: 24px;
        margin-bottom: 15px;
      }

      .input-field-container {
        width: 90%;
        margin: 0;

        .input-field {
          z-index: 10;
          position: relative;
        }
      }

      .footer {
        left: unset;
      }
    }
  }
}
