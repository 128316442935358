.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;

  svg {
    cursor: default;
  }

  .login-title {
    font-size: 36px;
    font-family: 'openSans-extraBold';
    color: var(--cyan-clr);
    margin-bottom: 100px;

    span {
      font-family: 'openSans-extraBold';
    }
  }

  .arrow-back {
    margin-left: 20px;
    align-self: baseline;
    margin-top: 16px;
    cursor: pointer;
    width: 37px;
  }

  .logo {
    margin-top: 40px;
    width: 150px;
    margin-bottom: 50px;
  }

  .forget-password-img {
    height: 325px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 490px;
    max-width: 100vw;
    margin: 0 auto;

    .input-container {
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus {
        -webkit-text-fill-color: var(--white-clr);
        transition: background-color 5000s ease-in-out 0s;
        -webkit-box-shadow: 0 0 0 1000px rgb(1 255 217 / 5%) inset !important;
        -webkit-text-fill-color: var(--white-clr) !important;
      }
    }

    p {
      color: var(--white-clr);
      margin-bottom: 7px;
    }
  }

  .forget-password {
    text-align: left;
    color: var(--grey-clr);
    font-size: 14px;
    text-decoration: underline;
    align-self: end;
    margin: 8px 0 46px;
    cursor: pointer;
    width: 100%;
  }

  .login-btn {
    width: 119px;
    height: 32px;
    border: 0;
    border-radius: 10px;
    background-color: var(--main-clr);
    color: var(--text-clr);
    // font-family: 'openSans-extraBold';
    cursor: pointer;
    font-family: Assistant;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    // position: absolute;
    // right: 10%;
    // // bottom: -50%;
    // background-image: url(../../assets/next-btn-bcg.svg);
    // background-size: cover;
    // background-repeat: no-repeat;

    &:hover {
      // background-image: url(../../assets/next-btn-bcg-hover.svg);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;

      background-color: var(--lighter-grey-clr);
    }
  }

  .welcome-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .error-input {
    font-size: 12px;
    margin: 0;
    width: 100%;
    text-align: left;
    color: var(--red-2-clr);
    margin-bottom: 10px;
  }
}

.login-img {
  margin: 44px 0 60px;
  height: 250px;
}

@media only screen and (max-width: 600px) {
  .login-img {
    height: 80vw;
  }

  .forget-password {
    width: 98% !important;
  }
}

form input {
  background: transparent;
  // width: 490px;
  height: 40px;
  font-family: 'openSans-regular';
  max-width: 80vw;
  border-radius: 3px;
  border: solid 1px #999;
  font-size: 16px;
  // color: var(--white-clr);
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  outline: none;

  &:last-child {
    margin-bottom: 0px;
  }
}

form input:focus {
  border: solid 1px #f1a9ff;
}

form input:focus-visible {
  border: solid 1px #f1a9ff;
}

form input::placeholder {
  color: var(--ligher-blue-clr);
}

.modal-full-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(44, 44, 44, 0.46);
  z-index: 999;

  .modal-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px;
    // width: 55%;
    background-color: var(--white-clr);
    border-radius: 16px;
    border: solid 1px #979797;
    z-index: 200;
    height: auto;
    max-height: calc(100vh - 200px);
    max-width: 1000px;
    z-index: 999;
    overflow-y: auto;

    .modal-upper {
      text-align: center;

      h2 {
        margin-bottom: 7px;
        font-family: Assistant;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
      }

      span {
        color: var(--text-clr);
      }
    }
  }

  .bottom-credit {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 0;
    left: 45%;
    bottom: 2%;
    gap: 12px;

    h4 {
      color: var(--text-clr);
      font-family: Assistant;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.loading-icon-login {
  height: 68px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
