.main-page {
  .toggle-col {
    // display: flex;
    display: none;

    flex-direction: column;
    gap: 7px;
  }
  .header {
    width: 100%;
    height: 60px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    position: fixed;
    z-index: 1000;
    color: var(--dark-blue-clr);
    font-size: 20px;
    
    .dropdown {
      justify-self: right;
      position: relative;
      z-index: 10000;
      button {
        border-radius: 10px;
        border: 1px solid #132a49;
        background: var(--white-clr);
        width: 160px;
        height: 43px;

        color: var(--dark-blue-clr);

        margin-top: 22px;
        margin-right: 40px;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }

      .options {
        position: absolute;
        z-index: 10000;
        border-radius: 10px;
        background: var(--white-clr);
        // left: -41%;
        width: 246px;
        height: 125px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-evenly;
        padding-left: 15px;

        .model-option {
          display: flex;
          flex-direction: row-reverse;
          gap: 10px;
          font-size: 18px;
        }
      }
    }
    .new-conversation-container {
      border: 1px solid var(--text-clr);
      cursor: pointer;
      border-radius: 10px;
      display: flex;
      padding: 7px 15px;
      align-items: center;
      gap: 10px;
      // background-color: var(--secondary-clr);

      p {
        color: var(--text-clr);
        font-size: 20px;
        font-weight: 600;
      }
    }

    .left-side {
      display: flex;
      align-items: center;
      padding-left: 23px;
      gap: 87px;
      .my-subscription {
        display: flex;
        align-items: center;
        gap: 5px;
        p {
          font-size: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .main-page {
    .header {
      height: 59px;
      font-size: 10px;
      padding: 0 10px;

      svg {
        height: 50px;
      }
      .new-conversation-container {
        flex-direction: column;
        gap: 0;
        .new-conversation-btn {
          width: 21px;
          height: 21px;
        }
      }

      .left-side {
        gap: 16px;
        padding-left: 0;
      }
    }
  }
}
