/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@font-face {
  font-family: 'Heebo'; /*Can be any text*/
  src: url('./lib/fonts/Heebo-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSans'; /*Can be any text*/
  src: url('./lib/fonts/Heebo-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-black'; /*Can be any text*/
  src: url('./lib/fonts/Heebo-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-bold'; /*Can be any text*/
  src: url('./lib/fonts/Heebo-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-light'; /*Can be any text*/
  src: url('./lib/fonts/Heebo-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-medium'; /*Can be any text*/
  src: url('./lib/fonts/Heebo-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'montserrat-regular'; /*Can be any text*/
  src: url('./lib/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'montserrat-black'; /*Can be any text*/
  src: url('./lib/fonts/Montserrat-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'montserrat-bold'; /*Can be any text*/
  src: url('./lib/fonts/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'montserrat-light'; /*Can be any text*/
  src: url('./lib/fonts/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'montserrat-medium'; /*Can be any text*/
  src: url('./lib/fonts/Montserrat-Medium.ttf') format('truetype');
}

a {
  color: unset;
  text-decoration: unset;
}

.App {
  text-align: left;
  padding: 30px 0 0 60px;
  background-color: white;
}

svg,
img,
button {
  cursor: pointer;
}

textarea {
  height: 200px;
  width: 400px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
