body {
  padding-right: 0px;
  overflow: visible;
}
header {
  padding-right: 0px;
  overflow: visible;
}
.main-page {
  // overflow-y: scroll;
  .chat-page {
    padding-left: 40px;
    // display: flex;
    position: relative;
    // justify-content: space-between;
    height: 100vh;
    padding-top: 100px;
    flex-direction: column;
    background-size: cover;

    //// Test
    background-repeat: repeat-y;
    overflow-y: auto;
    height: 100%;
    min-height: 100vh;

    padding-top: 150px;
    padding-bottom: 130px;
    // background-image: url("../assets/background-desktop.png");
    // background-repeat: no-repeat;
    // background-size: cover;

    .flex-example-questions {
      font-family: Assistant;
      margin-top: 25px;
      display: flex;
      flex-wrap: nowrap;
      // align-items: center;
      margin-right: 35px;
      max-width: 70%;
      // color: var(--white-clr);
      // font-weight: 400;
      width: fit-content;
      gap: 15px;
      text-align: center;
      font-size: 18px;

      .icon-and-label {
        display: flex;
        gap: 10px;
        height: 45px;
        align-items: center;
        .questions-label {
          white-space: nowrap;
        }
      }
      // max-width: 85%;
      .flex-questions {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        .example-question-btn {
          // color: white;
          text-align: center;
          font-family: Assistant;
          font-size: 18px;
          outline: 0;
          border: 0;
          background: var(--lightest-grey);
          padding: 0.5em 1em;
          border-radius: 10px;
        }
      }
    }

    .yes-ai-img {
      margin: 30px 0 0;
      position: fixed;
      left: 40px;
      top: 90px;
      width: 200px;
    }
    .right-side {
      max-width: 50%;
      overflow-y: auto;
      max-height: calc(100vh - 210px);
      &::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
      }
    }

    .sources {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 10px;
    }
    .source {
      margin-top: 10px;
      display: flex;
      align-items: flex-start;
      // cursor: pointer;

      p {
        // text-decoration: underline;
        font-size: 16px !important;
      }
      span {
        text-decoration: none;
        font-size: 16px;
      }

      .svg {
        margin-top: 5px;
      }
    }

    .question-container {
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      backdrop-filter: blur(50px);
      background-color: var(--main-undertone-clr);
      padding: 20px 30px;
      color: var(--text-clr);
      // opacity: 0.75;
      margin: 10px 0;
      display: flex;
      align-items: center;
      max-width: 50%;
      margin: 25px 60px 25px 0;
      border-radius: 23px;
      width: fit-content;
    }
    .answer-container {
      border-radius: 23px;
      // border-top-right-radius: 0;
      background-color: var(--inputs-bg-color);
      padding: 20px 40px 12px 20px;
      color: var(--text-clr);
      margin: 10px 0;
      display: flex;
      align-items: flex-start;
      gap: 15px;
      width: -moz-fit-content;
      width: fit-content;
      opacity: 0.9;
      max-width: 50%;

      margin-right: 35px;
      position: relative;

      .triangle {
        position: absolute;
        width: 0;
        height: 0;
        border-top: 39px solid #e3e3e3cc;
        border-right: 16px solid transparent;
        border-radius: 8px;
        right: -14px;
        border-top-left-radius: 0;
        top: 0;
      }

      .left-triangle {
        position: absolute;
        width: 0;
        height: 0;
        border-top: 39px solid #e3e3e3cc;
        border-left: 16px solid transparent;
        border-radius: 8px;
        left: -14px;
        border-top-right-radius: 0;
        top: 0;
      }
      .idi-logo {
        width: 32px;
        height: 57px;
      }

      .line {
        width: 1px;
        height: 60px;
        border: solid 1px var(--text-clr);
      }

      // &:first-child {
      //   margin-top: 65px;
      // }

      .ans-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 45px;

        .bottom-container {
          // display: flex;
          // justify-content: flex-start;
          align-items: center;
          gap: 10px;
          width: 100%;
          // flex-direction: row-reverse;
          // flex-grow: 1;
          margin-top: 15px;
          margin-bottom: 15px;

          .like-container {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-end;
            cursor: pointer;
            position: relative;
            gap: 2px;
            bottom: -5px;
            p {
              font-size: 12px;
              color: var(--light-green-clr);
            }
          }
          .select-box-tool-box {
            direction: ltr;
            width: 250px;
            font-family: Assistant;
            background-color: white;
            height: 40px;
            border-radius: 8px;
            outline: none;
            font-size: 16px;
            border-color: white;
            color: var(--text-clr);
          }
 
          .flex-comment textarea {
            resize: none;
            min-height: 48px;
            height: 79px;
            width: 100%;
            padding: 15px 10px 0px 10px;
            
            position: relative;
            font-family: Assistant;
            border-radius: 10px;
            font-size: 16px;
            color: black;
            border: none;
            
            .submit-btn-strip {
              display: flex;
              padding-bottom: 8px;
              // padding-right: 8px;
              padding-left: 0px;
              padding-top: 5px;
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
              align-items: flex-end;
              justify-content: flex-end;

            }
            .submit-btn {
              border-radius: 8px;
              display: flex;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              z-index: 1;
              line-height: 20px;
              border: none;
              background-color: var(--background-clr);
              color: var(--background-second-clr-lm);
              font-family: Assistant;
              padding: 6px 12px 6px 12px;
              justify-content: center;
              align-items: center;
              width: 60px;
            }
            outline: 0;
          }
          // .active-bg {
          //   background-color: var(--lighter-grey-clr);
          // }

          // .unactive-bg {
          //   background-color: transparent;
          // }
        }

        .img-source {
          font-size: 12px;
          color: var(--cyan-clr);
        }
        img {
          width: 100%;
          margin-bottom: 10px;
          cursor: default;
        }

        .link-to-source {
          display: flex;
          flex-direction: row-reverse;
          gap: 7px;
          align-items: center;
          img {
            width: 22px;
            margin-bottom: 0;
            cursor: pointer;
          }
        }

        .is-in-yes {
          color: var(--light-green-clr);
          text-decoration: underline;
          // margin-top: 15px;
        }
      }

      .yes-typing {
        height: 70px;
        width: 70px;
      }
      .yes-conv-icon {
        height: 54px;
        width: 75px;
        min-width: 75px;
        min-height: 54px;
        cursor: default;
      }
      .Typewriter__wrapper,
      p {
        font-size: 22px;
      }
    }
    .user-input {
      font-size: 22px;
      color: var(--text-clr);
      overflow-wrap: break-word;
    }
    // }

    // .input-field-container {
    //   width: 795px;
    //   position: fixed;
    //   bottom: 30px;
    //   // margin-right: 40px;
    //   bottom: 0;
    //   position: fixed;
    //   height: 100px;
    //   // backdrop-filter: blur(10px);
    //   padding-right: 40px;
    //   margin-right: 0;

    //   &.english {
    //     // padding-left: 40px;
    //     padding-right: 0;
    //   }

    //   input {
    //     bottom: 30px;
    //     // width: 755px;
    //   }
    // }
  }
}

@media only screen and (max-width: 1000px) {
  .main-page {
    .chat-page {
      padding-left: 0;
      padding-top: 100px;

      .flex-example-questions {
        font-family: Assistant;
        flex-direction: column;
        // font-size: 14px;
        gap: 10px;
        max-width: 90%;
        margin-right: auto;
        // margin-left: auto;
        .flex-questions {
          flex-direction: column;
        }
        .example-question-btn {
          font-size: 14px;
          text-align: right;
        }
      }

      .input-field-container {
        width: 90%;
        bottom: 20px;
        left: 50%;
        margin: 0;
        -webkit-transform: translate(-50%);
        transform: translate(-50%);
        height: unset;
        padding-right: 0;
        margin-right: 0;
        display: flex;
        align-items: center;
        input {
          width: 100%;
        }
      }

      .yes-ai-img {
        position: unset;
        margin: 30px auto 25px;
        width: 100%;
        height: 30px;
      }
      .right-side {
        max-width: unset;
        max-height: calc(100vh - 260px);
      }

      .sources {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 10px;
      }
      .source {
        margin-top: 10px;
        display: flex;
        // text-decoration: underline;
        align-items: flex-start;
      }

      // .conversation {
      //   width: 85%;
      //   margin-top: 0;
      //   margin-bottom: 0;
      .question-container {
        padding: 12px 20px;
        max-width: 75%;
        margin-right: 30px;
        color: var(--white-clr);
      }

      .answer-container {
        padding: 12px 20px;
        max-width: 85%;
        background-color: var(--inputs-bg-color) !important;
        align-items: center;
        min-height: 58px;
        background-color: var(--white-clr) fff;
        svg {
          min-width: 20px;
          min-height: 20px;
          max-width: 20px;
          max-height: 20px;
          margin-top: 5px;
        }
        .line {
          height: 31px;
        }
        .triangle {
          right: -10px;
        }
        .left-triangle {
          left: -10px;
        }
        .ans-container {
          min-height: 30px;
        }

        &:first-child {
          margin-top: 0;
        }
        .yes-typing {
          height: 30px;
          width: 30px;
        }
        .yes-conv-icon {
          height: 54px;
          width: 60px;
          min-width: 60px;
          min-height: 54px;
        }
        .Typewriter__wrapper,
        p {
          font-size: 16px;
          margin-top: 0;
        }
      }
      .user-input {
        font-size: 16px;
      }
    }
  }
  // }
}

.Typewriter__cursor {
  display: none !important;
}
