.flex-files {
  align-items: center;
  display: flex;
  max-width: 760px;
  gap: 5px;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.flex-files .file {
  background-color: var(--lighter-grey-clr);
  border-radius: 10px;
  padding: 0.5em 1em;
}

.icon-name-container {
  display: flex;
  gap: 8px;
  font-family: Assistant;
  width: fit-content;
  align-items: center;
  font-size: 18px;
}

.icon-name-container img {
  width: 24px;
  height: 24px;
}

button {
  background-color: transparent;
  outline: none;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
