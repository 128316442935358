.input-field-container {
  position: relative;
  margin-right: 40px;
  .input-field {
    height: 55px;
    width: 100%;
    font-family: Assistant;
    margin-bottom:20px;
    padding-left: 60px;
    border-radius: 10px;
    background-color:var(--inputs-bg-color);
    border: 0;
    font-size: 20px;
    padding-right: 32px;
    color: var(--text-clr);

    &::placeholder {
      color: var(--text-clr);
      opacity: 0.4;
    }

    &:focus-visible {
      outline-width: 0;
    }
    &:focus:not(.focus-visible) {
      outline: none;
    }
  }
  .line {
    height: 45px;
    width: 1px;
    background: white;
    right: 15px;
    position: absolute;
  }
  .get-ans-btn {
    position: absolute;
    // left: 2px;
    z-index: 10;
    
    top: 8px;

    &:focus {
      outline: none !important;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .input-field-container {
    .input-field {
      // margin-bottom: 20px;

      font-size: 16px;
    }
  }
}